<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-100">
                <div class="w-90 center">
                    <router-link :to="{name: 'ManageAssets'}">&lt; Back</router-link>
                </div>
                <div class="f3 b w-90 center pv4" style="padding-right: 2px">Equipment</div>
                <div class="w-90 center mv4 adj-text">
                    <div class="flex flex-wrap">
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2">Transaction</div>
                            </div>
                            <div class="f5 f4-l font-w2">2000</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2">Total Debits</div>
                            </div>
                            <div class="f5 f4-l font-w2">2000</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2">Total Credits</div>
                            </div>
                            <div class="f5 f4-l font-w2">2000</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2">Balance (Total)</div>
                            </div>
                            <div class="f5 f4-l font-w2">2000</div>
                        </div>
                    </div>
                </div>
              <div class=" flex justify-between pb3 box-border-bottom w-90 center">
                <div></div>
                <div class="flex flex-wrap justify-between items-center w-50-l w-100 mt0-l mt2">
                    <select @change="changeDateFilter" class="card-box mb3 mb0-l" id="" style="padding: 13px 0">
                        <option value="">All</option>
                        <option value="last7Days">Last 7days</option>
                        <option value="currentMonth">This Month</option>
                        <option value="lastMonth">Last Month</option>
                    </select>
                    <input
                        type="date"
                        name=""
                        class="input-style card-box mb3 mb0-l"
                        placeholder="Search..."
                        style="padding: 13px 0"
                    />
                    <input
                        type="date"
                        name=""
                        class="input-style card-box mb3 mb0-l"
                        placeholder="Search..."
                        style="padding: 13px 0"
                    />
                    <div class="filter card-box" style="padding: 13px 0">
                        <div class="tc flex items-center">
                            <img src="imgs/filter_list.svg" class="pl3" alt="" />
                            <router-link to="#" class="pl4">Filter</router-link>
                        </div>
                    </div>
                </div>
              </div>
                <div class="scrollmenu w-90 center">
                    <table class="w-100 tl mt3" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="pb3 pr3 box-border-bottom tl">Date</th>
                                <th class="pb3 pr3 box-border-bottom tl">Inflows</th>
                                <th class="pb3 pr3 box-border-bottom tl">Outflows</th>
                                <th class="pb3 box-border-bottom tl pr2">Balance</th>
                                <th class="pb3 pr3 box-border-bottom tl">Description</th>
                                <th class="pb3 pr3 box-border-bottom tl">Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="pv3 pr3 box-border-bottom tl">13/11/2023</td>
                                <td class="pv3 pr3 box-border-bottom tl">N50000</td>
                                <td class="pv3 pr3 box-border-bottom tl">N4000</td>
                                <td class="pv3 box-border-bottom tl pr2">N1230000</td>
                                <td class="pv3 pr3 box-border-bottom tl">Buying my dream house</td>
                                <td class="pv3 pr3 box-border-bottom tl">12/11/2023</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                  <!-- pagination -->
            <div class="w-90 center pb5 font-w1">
                <div class="flex justify-between items-center pa3" style="background: #f6f8fe">
                    <div class="flex items-center">
                        <div class="pr3-l pr2">Showing</div>
                        <!-- w-20 -->
                        <input
                            type="number"
                            name=""
                            class="input-style w-20"        
                            style="border: 1px solid rgb(151, 145, 145); background: #ffffff"
                        />
                        <div class="pl3 db-l dn">items per page</div>
                    </div>
                    <div class="flex">
                        <router-link to="#" class="active-page-number">1</router-link>
                        <!-- <router-link to="#" @click="changePageNumber(state.pageNumber + 1)" class="active-page-number pl2 pl3-l">{{state.pageNumber + 1}}</router-link> -->
                        <router-link  to="#"  class="pl2 pl3-l"
                            >Next</router-link
                        >
                        <router-link to="#" class="pl2 pl3-l">End</router-link>
                    </div>
                    <!-- Paginate -->
                </div>
            </div>
            <!-- pagination -->
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import AppWrapper from '@/layout/AppWrapper.vue'
export default {
    name: 'ChartView',
    components: { AppWrapper },

    setup() {
        return {}
    },
}
</script>
<style>
.custom-active {
    /* color: #132c8c; */
    color: #132c8c;
    display: inline-block;
}
.active-menu {
    color: #132c8c;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px !important;
    border-bottom: 3px solid #132c8c;
    font-weight: 500;
}
</style>
